<template>


  <div class="info"  v-if="props.strainData">
     

    <BrandTreeHorizontal
      :title="$t('strain_view_subtitle_family_tree')"   
      :tree="props.strainData.tree" 
      />
      <br>
      
    <BrandSimilarProductsBig
      :title="props.strainData.name + ' Breeder products'"   
      :list="props.strainData.items_product" 
      :show-strain-creator="true"
      type="product"
      :show-brand="true"
      />
      <br>

      

     
    <BrandDiaries v-if="props.strainData.items_diary && props.strainData.items_diary.length"
      :title="$t('strain_view_popular_diaries')"
      :showmore="$t('strain_view_show_all_diaries')"      
      :list="props.strainData.items_diary.filter(b => {return b.group_name === 'top'})"
      :cnt="props.strainData.item_stat?.cnt_reports"
      :link="$route.path + '/diaries'"
    />   

    <br>
    
    <BrandShorts  
      :title="'Shorts'"
      :showmore="'Show all'"
      :tag="'st:' + props.strainData.id + ':' + props.strainData.section"
    />


    <!-- <div class="cpm"></div> -->
 
    <br>

    <BrandDiaries v-if="props.strainData.items_diary && props.strainData.items_diary.length"
      :title="$t('strain_view_subtitle_new_diaries')"
      :showmore="$t('strain_view_show_all_diaries')"      
      :list="props.strainData.items_diary.filter(b => {return b.group_name === 'new'})"
      :cnt="props.strainData.item_stat?.cnt_reports"
      :link="$route.path + '/diaries'"
    />

    <br>
     
    <BrandHarvestItems
      :title="$t('strain_view_subtitle_latest_harvests')"
      :showmore="$t('breeder_view_show_all_harvests')"     
      :link="$route.path + '/harvests'" 
      :list="props.strainData.items_harvest"
      :cnt="props.strainData.item_stat?.cnt_harvests"
    />    

    <br>
     
    <BrandReviewItems
      :title="$t('strain_view_latest_reviews')"
      :showmore="$t('breeder_view_show_all_reviews')"      
      :link="$route.path + '/reviews'"  
      :list="props.strainData.items_review"
      :cnt="props.strainData.item_stat?.cnt_reviews"
    />    



    <StrainSimilarBig
      :title="'Similar strains'"
      :list="props.strainData.items_similar" 
      type="product"
      :show-brand="true"
    />
    
    <br>

      

    <CommentWidget v-if="props.strainData.id"
      :title="$t('strain_view_subtitle_latest_comments')"
      :type="'strain'"
      :content="props.strainData.id"
    />



  </div>
</template>


<script setup lang="ts">

import type { Strain } from '@/types/strain'

interface PageProps {
  strainData: Strain
}

const props = defineProps<PageProps>()
  
useHead(useNuxtApp().$head.getStrainInfo(props.strainData));

</script>

  

<style scoped>

.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}
.flow_boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

</style>
